<template>
  <header>
    <div class="brand">
      <router-link to="/"
        ><img src="@/assets/logo-brand.png" alt=""
      /></router-link>
    </div>
    <nav
      :class="{ display: isMenuOpen, hidden: !isMenuOpen }"
      @click="(isMenuOpen = false), (isOpen = false)"
    >
      <router-link to="/about">About</router-link>
      <router-link to="/career">Career</router-link>
      <router-link to="/culture">Culture</router-link>
    </nav>
    <div class="hamburger" @click="toggleMenu">
      <img src="@/assets/menu.png" alt="" v-if="!isOpen" />
      <img src="@/assets/close.png" alt="" class="close" v-else />
    </div>
  </header>
</template>

<script>
import { ref } from "vue";
export default {
  name: "HeaderComp",
  setup() {
    const isOpen = ref(false);
    const isMenuOpen = ref(false);
    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
      isOpen.value = !isOpen.value;
    };
    return {
      toggleMenu,
      isOpen,
      isMenuOpen,
    };
  },
};
</script>

<style lang="scss">
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 5px 5%;
  background: #fff;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  @media (min-width: 768px) {
    padding: 5px 10%;
  }
  .brand {
    img {
      height: 90px;
      filter: grayscale(1) brightness(0);
      transition: all 0.3s ease-in-out;

      &:hover {
        filter: none;
      }
    }
  }
  .hamburger {
    cursor: pointer;
    img {
      width: 30px;
    }

    .close {
      width: 20px;
    }
  }
  nav {
    display: none;
    a {
      color: #000;
      margin: 0 18px;
      text-decoration: none;
      text-transform: uppercase;

      &.router-link-exact-active {
        color: #42b983;
        font-weight: bold;
      }
    }
    @media (min-width: 768px) {
      a:last-child {
        margin-right: 0;
      }
    }
  }

  @media (min-width: 768px) {
    .hamburger {
      display: none;
    }

    nav {
      display: block;
    }
  }

  @media screen and (max-width: 768px) {
    nav.display {
      display: block;
      position: absolute;
      top: 76px;
      background: white;
      width: 100%;
      left: 0;
      padding: 20px 0;

      a {
        display: block;
        padding: 20px;
      }
    }
  }
}

header nav a.router-link-exact-active {
  color: #000;
  font-weight: 800;
}
</style>
