<template>
  <div class="home">
    <div class="section-1">
      <div class="container">
        <BannerComp />
      </div>
    </div>
    <div class="section-2">
      <div class="container">
        <div class="heading">MOVIE</div>
        <div id="projects">
          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(slide, idx) in movies" :key="idx">
              <div class="carousel__items">
                <router-link :to="`video/${slide.id}`"
                  ><img
                    :src="require(`@/assets/home/${slide.image}.jpg`)"
                    alt=""
                /></router-link>
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <div class="section-3">
      <div class="container">
        <div class="heading">OUR WORKS</div>
        <div id="projects">
          <Carousel v-bind="settings" :breakpoints="breakpoints">
            <Slide v-for="(slide, idx) in works" :key="idx">
              <div class="carousel__items">
                <router-link :to="`video/${slide.id}`" v-if="slide.youtube"
                  ><img
                    :src="require(`@/assets/home/${slide.image}.jpg`)"
                    alt=""
                /></router-link>
                <img
                  v-else
                  :src="require(`@/assets/home/${slide.image}.jpg`)"
                  alt=""
                />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <!-- Keep in touch -->
    <div class="section">
      <div class="container">
        <div class="row order">
          <div class="col">
            <img src="@/assets/home/form.jpg" alt="" />
          </div>
          <div class="col">
            <Form />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import Form from "@/components/FormComp.vue";
import "vue3-carousel/dist/carousel.css";
import videoJson from "/videoMovie.json";
import BannerComp from "@/components/BannerComp.vue";

const settings = {
  itemsToShow: 1,
  autoplay: 2000,
  snapAlign: "center",
  wrapAround: true,
};

const breakpoints = {
  500: {
    itemsToShow: 3,
    snapAlign: "center",
  },
};

const movies = ref([]);
const works = ref([]);

onMounted(() => {
  movies.value = videoJson.filter((item) => item.type === "movie");
  works.value = videoJson.filter((item) => item.type === "work");
});
</script>

<style lang="scss">
.carousel__next {
  right: -35px;
}
.carousel__prev {
  left: -35px;
}
.carousel__viewport {
  padding: 30px 0;
}
.section-2 {
  padding: 3rem 0;

  @media (min-width: 768px) {
    padding: 6rem 0;
  }
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
}
.section-3 {
  padding: 0 0 3rem;

  @media (min-width: 768px) {
    padding: 0 0 6rem;
  }
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }
}
.section-4 {
  background: #000;
  padding: 3rem 0 1rem;

  @media (min-width: 768px) {
    padding: 6rem 0 1rem;
  }
  .heading {
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0px;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  #projects {
    .carousel__prev,
    .carousel__next {
      box-sizing: content-box;
      border-radius: 50%;
      border: none;
      padding: 0px;
      background: #5e5e5e;
    }
    img {
      height: 100px;
      width: auto !important;
      object-fit: contain;
      border-radius: 0;
    }
  }
}

#projects {
  img {
    border-radius: 16px;
  }
  .carousel {
    ol {
      margin: 0;
    }
  }
  .carousel__item {
    min-height: 400px;
    width: 100%;
    background-color: #5bbb38;
    color: #fff;
    font-size: 20px;
    // border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel__items {
    img {
      width: 100%;
    }
  }
  .carousel__item.active {
    background-color: #1f6948;
  }
  .carousel__slide {
    padding: 10px !important;
  }
  .carousel__prev,
  .carousel__next {
    box-sizing: content-box;
    border: 1px dashed black;
    border-radius: 50%;
    padding: 10px;
    background: #fff;
  }

  .logo-brand {
    padding: 30px;
    img {
      width: 100%;
    }
  }
}

.section {
  padding: 0 0 3rem;
  text-align: center;

  @media (min-width: 768px) {
    padding: 0 0 6rem;
    text-align: left;
  }
  .heading {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 16px;
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 768px) {
      font-size: 45px;
      width: 90%;
    }
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem 0;

    @media (min-width: 768px) {
      flex-direction: row;
      padding: 3rem 0;
      gap: 4rem;
    }

    .col {
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }
    }

    img {
      width: 100%;
    }
  }

  .order {
    @media (min-width: 768px) {
      .col:first-child {
        order: 2;
      }
      .col:last-child {
        order: 1;
      }
    }
  }

  .cta {
    margin-top: 3rem;
    a {
      background: #000;
      color: #fff;
      padding: 12px 24px;
      border-radius: 5px;
      text-decoration: none;
      font-weight: 800;
    }
  }

  form {
    margin-top: 4rem;

    input {
      padding: 8px 16px;
      font-size: 16px;
      display: block;
      margin-bottom: 16px;
      width: 100%;

      @media (min-width: 768px) {
        width: 50%;
      }
    }
  }
}
</style>
