<template>
  <div>
    <div class="section-4">
      <div class="container">
        <div class="heading">CLIENT & PARTNERS</div>
        <div id="projects">
          <Carousel v-bind="set_client" :breakpoints="bp_client">
            <Slide v-for="slide in 5" :key="slide">
              <div class="logo-brand">
                <img
                  :src="require(`@/assets/client/slide-${slide}.png`)"
                  alt=""
                />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
    <footer>
      <div class="brand">
        <router-link to="/"
          ><img src="@/assets/logo-brand.png" alt=""
        /></router-link>
      </div>
      <nav>
        <router-link to="/">Home</router-link>
        <router-link to="/about">About</router-link>
        <router-link to="/career">Career</router-link>
        <router-link to="/culture">Culture</router-link>
      </nav>
      <div class="sosmed-area">
        <div class="name">FOLLOW ANIMOTION</div>
        <div class="list">
          <a
            href="https://api.whatsapp.com/send/?phone=6283894328536"
            target="_blank"
            ><mdicon name="whatsapp" size="28"
          /></a>
          <a href="#" target="_blank"><mdicon name="facebook" size="28" /></a>
          <a href="#" target="_blank"><mdicon name="instagram" size="28" /></a>
          <a href="#" target="_blank"><mdicon name="youtube" size="28" /></a>
          <a href="mailto:animotionspictures@gmail.com" target="_blank"
            ><mdicon name="email" size="28"
          /></a>
        </div>
      </div>
      <div class="copyright">© 2023 Animotion all rights reserved</div>
    </footer>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data: () => ({
    set_client: {
      itemsToShow: 2,
      autoplay: 2000,
      snapAlign: "center",
      wrapAround: true,
    },
    bp_client: {
      500: {
        itemsToShow: 5,
        snapAlign: "center",
      },
    },
  }),
});
</script>

<style lang="scss" scoped>
footer {
  background: #000;
  padding: 20px 5%;
  color: #fff;

  @media (min-width: 768px) {
    padding: 20px 10%;
  }

  .brand {
    img {
      height: 120px;
      filter: grayscale(1) brightness(3);
      transition: all 0.4s ease-in-out;
    }

    img:hover {
      filter: none;
    }
  }

  nav {
    padding: 30px 0;

    @media (min-width: 768px) {
      padding: 48px 0;
    }
    a {
      margin: 0 8px;
      color: #fff;
      text-decoration: none;
      text-transform: uppercase;

      @media (min-width: 768px) {
        padding: 0 60px;
      }

      &.router-link-exact-active {
        font-weight: bold;
      }
    }
  }

  .sosmed-area {
    .name {
      font-size: 12px;
    }
    .list {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 0 40px;

      a {
        padding: 0 8px;
        color: #fff;

        @media (min-width: 768px) {
          padding: 0 20px;
        }
      }
    }
  }

  .copyright {
    text-align: center;
    text-transform: uppercase;
    font-size: 13px;
  }
}

.logo-brand {
  img {
    filter: grayscale(1) brightness(3);
    transition: all 0.4s ease-in-out;
  }

  img:hover {
    filter: none;
  }
}
</style>
