<template>
  <div>
    <div class="heading">Stay in touch</div>
    <div class="desc">
      Register today for the DreamWorks Animotion newsletter to receive all the
      latest information about our upcoming films and projects
    </div>
    <form>
      <input type="text" v-model="email" placeholder="Email" required />
      <input type="number" v-model="age" placeholder="Umur" required />
      <div class="cta">
        <a
          :href="`mailto:animotionspictures@gmail.com?subject=New subcriber DreamWorks Animotion&body=&body=email: ${email}, age: ${age}`"
          >Submit</a
        >
      </div>
    </form>
  </div>
</template>

<script>
// @ is an alias to /src
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormView",
  data: () => ({
    email: "",
    age: "",
    isLoading: true,
  }),
  methods: {},
  mounted() {},
});
</script>
