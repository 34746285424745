<template>
  <Header />
  <div class="main-content">
    <router-view />
  </div>
  <Footer />
</template>

<script>
import Header from "@/components/HeaderComp.vue";
import Footer from "@/components/FooterComp.vue";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap");
#app {
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer !important;
}

body {
  margin: 0;

  img {
    max-width: 100%;
  }

  .main-content {
    color: #2c3e50;
    margin-top: 76px;
  }

  .container {
    padding: 0 5%;

    @media (min-width: 768px) {
      padding: 0 7%;
    }
  }
}
</style>
